.product-details-page {
  padding-top: 8rem; // Space for header

  .navigation-bar {
    background: #fff;
    padding: 1.5rem 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 3rem;

    .container {
      max-width: 120rem;
      margin: 0 auto;
      padding: 0 2rem;
    }

    .back-link {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: #009a4a;
      font-size: 1.6rem;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.2s ease;

      .back-arrow {
        width: 1.6rem;
        height: 1.6rem;
        transition: transform 0.3s ease;
      }

      &:hover {
        color: #008540;
        .back-arrow {
          transform: translateX(-4px);
        }
      }
    }
  }

  .product-details-container {
    padding: 2rem 0;
  
    .product-main {
      display: grid;
      grid-template-columns: 45% 55%;
      gap: 4rem;
      margin-bottom: 4rem;
      align-items: start;
  
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2rem;
        margin-bottom: 2rem;
      }
    }
  
    .product-gallery {
      position: sticky;
      top: 100px;
  
      @media (max-width: 768px) {
        position: static;
        width: 100%;
      }
  
      .main-image {
        margin-bottom: 2rem;
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        aspect-ratio: 1 / 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f8f9fa;
  
        @media (max-width: 768px) {
          width: 100%;
          max-height: 60vh;
          margin-bottom: 1rem;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
      }
  
      .thumbnail-list {
        display: flex;
        gap: 1rem;
        overflow-x: auto;
        padding-bottom: 1rem;
        scrollbar-width: none;
  
        &::-webkit-scrollbar {
          display: none;
        }
  
        @media (max-width: 768px) {
          -webkit-overflow-scrolling: touch;
          padding-bottom: 0.5rem;
        }
  
        .thumbnail {
          width: 6rem;
          height: 6rem;
          border-radius: 0.5rem;
          overflow: hidden;
          cursor: pointer;
          border: 2px solid transparent;
          transition: border-color 0.2s;
          flex-shrink: 0;
  
          &.active {
            border-color: var(--primary-color);
          }
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  
    .product-info {
      @media (max-width: 768px) {
        width: 100%;
        padding: 0 1rem;
      }
  
      .category-tag {
        display: inline-block;
        padding: 0.5rem 1rem;
        background: var(--primary-color);
        color: white;
        border-radius: 2rem;
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
  
      .product-name {
        font-size: 3.2rem;
        margin-bottom: 1.5rem;
        color: #333;
  
        @media (max-width: 768px) {
          font-size: 2.4rem;
        }
      }
  
      .product-short-description {
        font-size: 1.6rem;
        line-height: 1.6;
        color: #666;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #e9ecef;
  
        @media (max-width: 768px) {
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
          padding-bottom: 1.5rem;
        }
      }
  
      .product-cta {
        margin-top: 2rem;
  
        @media (max-width: 768px) {
          margin-top: 1.5rem;
        }
      }
    }
  }

  .product-tabs {
    margin-bottom: 4rem;

    .tab-buttons {
      display: flex;
      gap: 2rem;
      border-bottom: 1px solid #e9ecef;
      margin-bottom: 2rem;

      .tab-button {
        padding: 1rem 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: #666;
        background: none;
        border: none;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: all 0.2s;

        &.active {
          color: var(--primary-color);
          border-bottom-color: var(--primary-color);
        }

        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .tab-content {
      font-size: 1.6rem;
      line-height: 1.6;
      color: #444;

      .description-content {
        h2 {
          font-size: 2.4rem;
          margin-bottom: 1.5rem;
          color: #333;
        }

        h3 {
          font-size: 2rem;
          margin: 2rem 0 1rem;
          color: #444;
        }

        p {
          margin-bottom: 1.5rem;
        }

        ul {
          list-style-type: disc;
          margin: 1rem 0;
          padding-left: 2rem;

          li {
            margin-bottom: 0.5rem;
          }
        }
      }

      .specifications-table {
        width: 100%;
        border-collapse: collapse;

        th, td {
          padding: 1.5rem;
          border: 1px solid #e9ecef;
          text-align: left;
        }

        th {
          background: #f8f9fa;
          font-weight: 500;
          width: 30%;
        }

        tr:hover {
          background: #f8f9fa;
        }
      }
    }
  }

  .product-form-section {
    background: #f8f9fa;
    padding: 4rem 0;

    h2 {
      font-size: 2.4rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    form {
      max-width: 60rem;
      margin: 0 auto;
      background: white;
      padding: 3rem;
      border-radius: 1rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .form-group {
        margin-bottom: 2rem;

        label {
          display: block;
          font-size: 1.6rem;
          margin-bottom: 0.5rem;
          color: #444;
        }

        input {
          width: 100%;
          padding: 1rem;
          font-size: 1.6rem;
          border: 1px solid #ddd;
          border-radius: 0.5rem;

          &:focus {
            outline: none;
            border-color: var(--primary-color);
          }

          &[readonly] {
            background: #f8f9fa;
          }
        }
      }

      .btn-submit {
        background: #009a4a;
        color: #fff;
        border: none;
        padding: 1.2rem;
        font-size: 1.4rem;
        cursor: pointer;
        transition: all 0.2s ease;
        font-weight: 500;
        letter-spacing: 0.01em;
        border-radius: 5px;
        width: 100%;
        margin-top: 2rem;

        &:hover {
          background: #008540;
          opacity: 0.9;
        }

        &:active {
          opacity: 1;
        }
      }
    }

    .success-message {
      text-align: center;
      color: #28a745;
      font-size: 1.8rem;
      margin-top: 2rem;
      padding: 1.5rem;
      background: #d4edda;
      border-radius: 0.5rem;
    }
  }

  .button-group {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;

    .btn {
      flex: 1;
      padding: 1.2rem;
      font-size: 1.4rem;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      transition: all 0.2s ease;
      font-weight: 500;
      letter-spacing: 0.01em;
      border-radius: 5px;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 1;
      }
    }

    .btn-buynow {
      background: #009a4a;
      color: #fff;

      &:hover {
        background: #008540;
      }
    }

    .btn-product-site {
      background: #fff;
      color: #009a4a;
      border: 1px solid #009a4a;

      &:hover {
        background: #f8f9fa;
      }
    }
  }
}
