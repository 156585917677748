@import "src/styles/variables.scss";
@import "src/styles/common.scss";

// Banner
.aboutBanner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  height: calc(100vh - 56px);
  margin-top: 56px;

  &Content {
    position: relative;
    height: 100%;
  }

  &NewContainer {
    height: 100%;
  }

  &Description {
    color: var(--White, $color-white-0);
    @include font-reg(2rem);
    line-height: 32px;
    margin: 16px 0 32px;
    max-width: 751px;
  }

  &Btn {
    border-radius: 4px;
    background: var(--Primary, $color-green-0);
    padding: 10px 24px;
    color: $color-white-0;
    @include font-med(1.6rem);
    font-family: Figtree;
    border: none;

    &:hover {
      background: $color-green-7;
    }
  }

  &Heading {
    position: absolute;
    max-width: 1023px;
    display: flex;
    align-items: center;
    height: 100%;

    h1 {
      @include font-bold($fs: 4.8rem);
      color: #fff;
      line-height: 140%;
    }
  }

  @media (max-width: 576px) {
    &Heading {
      h1 {
        font-size: 3.2rem;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    & {
      height: calc(100vh - 56px);
      background-position: 85% 0;
      margin-top: 56px;
    }

    &Description {
      @include font-reg(1.4rem);
      line-height: 32px;
      margin: 8px 0 16px;
      max-width: 328px;
    }
  }
}

// challenge
.challenges {
  padding: 100px 0;

  &Images {
    max-width: 889px;
    margin: 0 auto;
  }

  @media (max-width: 992px) {
    padding: 48px 0px;
  }
}

// Brands
.common {
  padding: 100px 0;

  &Heading {
    text-align: center;

    p {
      @include font-reg($fs: 1.6rem);
      line-height: 26px;
      color: $color-gray-3;
      text-align: center;
      padding: 16px 0 56px;
    }
  }

  &Title {
    h4 {
      @include font-bold($fs: 2.4rem);
      color: $color-blackCurrant-0;
      padding-bottom: 24px;
    }
  }

  &SubsidiariesContainer {
    @include d-flex($jc: space-between);
    position: relative;

    &Cards {
      border-radius: 12px;
      background: $color-white-0;
      padding: 16px;
      cursor: pointer;
      min-height: 227px;

      &:hover {
        box-shadow: 6px 6px 40px 0px rgba(0, 0, 0, 0.08);
      }

      p {
        color: $color-green-1;
        @include font-med($fs: 2rem);
        padding: 24px 0;
        max-width: 346px;
      }

      a {
        @include d-flex($jc: flex-start);
        gap: 8px;
        color: $color-green-0;
        @include font-med($fs: 1.6rem);

        svg {
          path {
            stroke-width: 1.5px;
          }
        }

        &:hover {
          color: $color-green-7;

          svg {
            transform: translate(4px, -4px);
            transition: all 0.2s ease;
            path {
              stroke: $color-green-7;
            }
          }
        }
      }
    }

    &Logo {
      @include d-flex($jc: space-between);
      border-bottom: 1px solid $color-OysterBay-0;
      padding-bottom: 24px;

      img {
        width: 122px;
        height: 55px;
      }

      button {
        @include font-semi-bold($fs: 1.6rem);
        color: $color-green-0;
        border-radius: 6px;
        background: $color-green-3;
        padding: 6px 16px;
        border: 0;
        outline: 0;
      }
    }

    &Proposed {
      display: flex;
      gap: 32px;
      padding: 32px 0 56px;

      &Cards {
        background: url(../../assets/images/aboutUs/proposedBackground.svg)
          $color-green-2;
        background-repeat: no-repeat;
        background-position: right bottom;
        padding: 24px;
        border-radius: 12px;
        background-size: 55%;
        width: 100%;
        overflow: hidden;
        background-size: 65% 100%;
        background-position: 100% 35px;
        cursor: pointer;

        a {
          @include d-flex($jc: flex-start);
          gap: 8px;
          color: $color-green-0;
          @include font-med($fs: 1.6rem);
          padding-top: 24px;

          svg {
            path {
              stroke-width: 1.5px;
            }
          }

          &:hover {
            color: $color-green-7;

            svg {
              transform: translate(4px, -4px);
              transition: all 0.2s ease;
              path {
                stroke: $color-green-7;
              }
            }
          }
        }

        .backgroundLines {
          position: absolute;
          bottom: -20px;
          right: 4px;
          width: 389px;
          height: 92px;
        }
      }

      &Contents {
        border-bottom: 1px solid $color-OysterBay-0;
        @include d-flex($jc: space-between);

        p {
          color: $color-green-1;
          @include font-med($fs: 2rem);
          line-height: 32px;
        }

        button {
          color: $color-green-0;
          @include font-semi-bold($fs: 1.6rem);
          padding: 6px 16px;
          background: $color-green-3;
          border: 0;
          outline: 0;
        }
      }
    }
  }

  &Brands {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    &Cards {
      padding: 16px;
      border-radius: 12px;
      background: $color-white-0;
      max-width: 378px;

      .headingSpan {
        @include font-semi-bold($fs: 2rem);
        color: $color-green-1;
        line-height: 26px;
        padding-top: 16px;
        display: inline-block;
      }

      .headingSpan {
        span {
          @include font-med($fs: 1.6rem);
          color: $color-green-1;
          line-height: 26px;
        }
      }

      p {
        @include font-reg($fs: 1.6rem);
        color: $color-gray-3;
        line-height: 26px;
        max-width: 346px;
        padding-top: 8px;
      }
    }

    &Logo {
      border-bottom: 1px solid $color-green-4;
      padding-bottom: 16px;
    }
  }

  @media (max-width: 1180px) {
    &SubsidiariesContainer {
      flex-direction: column;
      align-items: inherit;
      gap: 24px;

      &Proposed {
        flex-direction: column;
        gap: 24px;

        &Contents {
          gap: 0.75rem;
        }
      }
    }

    &Brands {
      flex-direction: column;
      gap: 24px;

      &Cards {
        max-width: 100%;

        p {
          max-width: 100%;
        }
      }
    }
  }

  @media (max-width: 992px) {
    padding: 48px 0;

    .commonHeading h2 {
      @include font-bold($fs: 2.4rem);
    }

    .commonHeading p {
      @include font-reg($fs: 1.4rem);
      padding: 12px 0 32px;
    }

    &Title {
      h4 {
        @include font-bold($fs: 2rem);
        padding-bottom: 32px;
      }
    }

    &SubsidiariesContainer {
      &Cards {
        min-height: 0;
        p {
          @include font-med($fs: 1.6rem);
          padding: 12px 0 16px;
        }

        a {
          @include font-med($fs: 1.4rem);
        }
      }

      &Logo {
        padding-bottom: 12px;

        button {
          @include font-semi-bold($fs: 1.4rem);
        }
      }

      &Proposed {
        padding: 24px 0 32px;

        &Cards {
          background-size: 63% 102%;
          background-position: 122% 48px;

          a {
            @include font-med($fs: 1.4rem);
            padding-top: 12px;
          }
        }

        &Contents {
          p {
            @include font-med($fs: 1.6rem);
            line-height: 24px;
          }

          button {
            @include font-semi-bold($fs: 1.4rem);
            white-space: nowrap;
          }
        }
      }
    }

    &Brands {
      &Logo {
        padding-bottom: 12px;
      }

      .headingSpan {
        padding-top: 12px;
      }

      &Cards {
        p {
          @include font-reg($fs: 1.4rem);
          line-height: 160%;
        }
      }

      .headingSpan {
        @include font-semi-bold($fs: 1.6rem);
      }
    }
  }
}

.Brands {
  background: #f8f8f8;
  background-image: url(../../assets/images/aboutUs/Pattern4.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
}

// team
.team {
  padding: 100px 0;

  &Heading {
    padding: 40px 0 40px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;

    .active {
      border: 1px solid $color-green-0;
      border-radius: 20px;
      color: $color-green-0;
    }

    li {
      @include font-med($fs: 1.8rem);
      line-height: 26px;
      color: $color-gray-3;
      padding: 4px 16px;
      cursor: pointer;
    }
  }

  &Paragraph {
    text-align: center;
  }

  &Tabs {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;

    &Icon {
      &:hover {
        svg {
          path {
            transition: 0.2s all ease-in;
            fill: #2d64bc;
          }
        }
      }
    }

    &Profile {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      align-items: flex-start;
    }

    &Image {
      overflow: hidden;
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      img {
        display: block;
        width: 100%;
        object-fit: cover;
        transition: all 0.5s ease;

        &:hover {
          transform: scale(1.1);
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          box-shadow: 6px 6px 40px 0px rgba(0, 0, 0, 0.08);
        }
      }
    }

    &Contents {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      flex-basis: 31%;
      flex-grow: 1;
      cursor: pointer;

      &:hover {
        transition: all 0.5s ease;
        box-shadow: 6px 6px 40px 0px rgba(0, 0, 0, 0.08);
      }

      &:hover {
        .teamTabsImage {
          img {
            transform: scale(1.1);
          }
        }
      }

      p {
        @include font-reg($fs: 1.6rem);
        color: $color-gray-3;
        line-height: 26px;
        max-width: 341px;
        border-top: 1px solid $color-green-4;
        max-width: 373px;
        margin: 0 16px;
        padding: 8px 0 16px;
      }
    }

    &Links {
      h5 {
        @include font-semi-bold($fs: 2.4rem);
        color: $color-blackCurrant-0;
        padding-bottom: 4px;
      }

      span {
        @include font-med($fs: 1.8rem);
        color: $color-green-1;
      }
    }
  }

  &Intra {
    .teamTabsContents {
      flex-grow: 0;
    }
  }

  &Advisory {
    .teamTabsContents {
      flex-grow: 0;
    }
  }

  .teamTabs:last-child {
    padding-bottom: 0;
  }

  &Paragraph {
    p {
      line-height: 26px;
      @include font-reg($fs: 1.6rem);
      color: $color-blackCurrant-0;
      max-width: 607px;
      margin: 0 auto;
      padding-bottom: 56px;
    }
  }

  @media (max-width: 1175px) {
    &Intra {
      .teamTabsContents {
        flex-basis: 40%;
        flex-grow: 1;
      }
    }

    &Advisory {
      .teamTabsContents {
        flex-basis: calc(31% - 1rem);
      }
    }
  }

  @media (max-width: 992px) {
    &Heading {
      padding: 32px 0 32px;
    }

    &Tabs {
      &Contents {
        flex-basis: 100%;
        flex-grow: 1;

        p {
          max-width: 100%;
        }
      }
    }

    &Advisory {
      .teamTabsContents {
        flex-basis: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    &Heading {
      gap: 8px;

      li {
        padding: 4px 12px;
        @include font-med($fs: 1.4rem);
        line-height: 16px;
      }
    }

    &Paragraph {
      p {
        @include font-reg($fs: 1.4rem);
        line-height: 160%;
        padding-bottom: 24px;
      }
    }

    &Tabs {
      gap: 24px;
      padding-bottom: 0;

      &Contents {
        flex-basis: 100%;

        p {
          @include font-reg($fs: 1.4rem);
          line-height: 160%;
          max-width: 100%;
        }
      }

      &Links {
        h5 {
          @include font-semi-bold($fs: 2rem);
        }

        span {
          @include font-med($fs: 1.6rem);
        }
      }

      &Icon {
        svg {
          path {
            transition: 0.2s all ease-in;
            fill: #2d64bc;
          }
        }
      }
    }

    &Intra {
      .teamTabsContents {
        flex-basis: 100%;
        flex-grow: 1;
      }
    }
  }
}

// Experience
.experience {
  background: $color-green-6;

  &Container {
    display: flex;
    justify-content: space-between;
    padding-top: 56px;
    position: relative;
  }

  &Wrapper {
    padding-bottom: 80px;
  }

  &Date {
    flex-basis: 20%;

    &One {
      position: absolute;
      top: 6%;
    }

    &Two {
      position: absolute;
      top: 24%;
    }

    &Three {
      position: absolute;
      top: 50%;
    }

    &Mobile {
      display: none;
    }
  }

  &Line {
    flex-basis: 6.23%;

    img {
      height: 100%;
      transform: scale(1);
    }
  }

  &Section {
    flex-basis: 60%;
  }

  &Image {
    padding-bottom: 32px;
  }

  &Contents {
    display: flex;
    padding-bottom: 24px;
    width: 100%;

    span {
      @include font-semi-bold($fs: 1.8rem);
      line-height: 26px;
      color: $color-green-0;
      width: 90px;
      white-space: nowrap;
      flex-shrink: 0;
    }

    p {
      @include font-reg($fs: 1.6rem);
      color: $color-green-1;
      line-height: 26px;
      max-width: 610px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  @media (max-width: 1120px) {
    &Line {
      flex-basis: 6.9%;
    }

    &Date {
      &One {
        position: absolute;
        // toDO
        // top: 7%;
      }

      &Two {
        position: absolute;
        // toDO
        // top: 28%;
      }
    }
  }

  @media (max-width: 1100px) {
    &Date {
      display: none;

      &Mobile {
        display: block;
        width: 121px;
        padding-bottom: 16px;
      }
    }

    &Image {
      padding-bottom: 16px;

      img {
        width: 100%;
      }
    }

    &Contents {
      p {
        width: 100%;
        max-width: 100%;
      }
    }

    &Line {
      display: none;
    }

    &Container {
      flex-direction: column;
    }

    &Wrapper {
      padding-bottom: 24px;
    }
  }

  @media (max-width: 768px) {
    .commonHeading {
      h2 {
        font-size: 24px;
      }
    }

    &Container {
      padding-top: 32px;
    }

    &Contents {
      padding-bottom: 18px;

      span {
        font-size: 1.6rem;
        flex-shrink: 0;
        width: 70px;
      }

      p {
        font-size: 1.4rem;
        max-width: 100%;
      }
    }

    &Contents:last-child {
      padding-bottom: 0;
    }
  }
}
