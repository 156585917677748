@import "/src/styles/variables.scss";

.header {
  background-color: $color-white-0;
  border-bottom: 1px solid #dbdbdb;
  position: fixed;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 6;
  top: 0;

  &Container {
    @include font-reg;
    @include d-flex($jc: space-between);
    position: relative;
    padding: 16px 16px;
    max-width: 1232px;
    margin: 0px auto;
  }

  &BharatMandi {
    max-width: 183px;
  }

  &DropDown {
    @include d-flex();
    gap: 8px;
    cursor: pointer;

    &:hover {
      color: $color-green-0;
      @include font-reg(1.6rem);

      svg {
        path {
          fill: $color-green-0;
        }
      }
    }
  }

  &DropDownContainer {
    position: relative;
  }

  &ChildLink {
    @include d-flex();
    position: absolute;
    flex-direction: column;
    background: $color-white-0;
    padding-top: 24px;
    text-align: center;
    z-index: 2;
    border-top: none;
    border-radius: 8px;
    text-align: left;
    // width: 115%;
    left: -13px;
  }

  &Links &ChildLink a {
    padding: 16px 35px 16px 16px;
    color: $color-gray-3;
    width: 100%;
    white-space: nowrap;
  }

  &ChildLink a:hover {
    background-color: $color-OysterBay-0;
    color: $color-green-1;
    border-radius: 8px;
  }

  &Data {
    @include d-flex(normal);
    gap: 32px;
    color: $color-blackCurrant-0;
  }

  &Links {
    @include d-flex($jc: space-between);

    .contactLink {
      display: none;
    }
  }

  .contactLinkMobile {
    display: block;
  }

  &Links a {
    color: $color-blackCurrant-0;
    line-height: 19.2px;
  }

  &Links a:hover {
    color: $color-green-0;
  }

  &ContactUs {
    background-color: $color-green-0;
    border: none;
    color: $color-white-0;
    padding: 10px 24px;
    @include font-med;
    cursor: pointer;
    border-radius: 4px;
  }

  &ContactUs:hover {
    background: $color-green-7;
  }

  &Menu {
    display: none;
    position: absolute;
    right: 23px;
    margin: -3px;
  }

  .active {
    color: $color-green-0;
    @include font-semi-bold(1.6rem);
    svg {
      path {
        fill: $color-green-0;
      }
    }
  }

  // responsive

  @media screen and (max-width: 992px) {
    &Container {
      padding: 16px 24px;
      flex-direction: column;
      place-items: flex-start;
      min-height: 56px;
      width: 100%;
      z-index: 3;
      background: $color-white-0;
      gap: 10px;
      justify-content: center;
    }

    &BharatMandi {
      max-width: 133px;
      height: 16px;
    }

    &Links > a:first-child {
      display: none;
      margin-top: 5px;
    }

    &ChildLink a:hover {
      background-color: white;
      color: $color-gray-3;
    }

    &Data {
      flex-direction: column;
      gap: 0px;
      width: 100%;
      align-items: flex-start;
      color: $color-blackCurrant-0;

      a {
        border-bottom: 0.5px solid var(--Stroke, #d8ede2);
        @include font-reg(1.6rem);
      }
    }

    &Links,
    &ContactUs {
      flex-direction: column;
      place-items: flex-start;
      width: 100%;
      font-size: 16px;
    }

    &ContactUs {
      align-items: center;
      width: 100%;
      padding: 12px 24px;
      margin-bottom: 32px;
    }

    &Menu {
      @include d-flex();
      cursor: pointer;
    }

    &Links a:nth-child(3),
    &Links a:nth-child(4) {
      @include d-flex();
      place-items: flex-start;
      flex-direction: column;
    }

    &DropDown {
      gap: 16px;
      @include font-reg(1.6rem);
      width: 100%;

      &:hover {
        color: $color-green-0 !important;
      }
    }

    &ChildLink {
      position: static;
      text-align: left;
    }

    &DropDownContainer {
      padding: 18px 16px;
      border-bottom: 0.5px solid var(--Stroke, #d8ede2);
      width: 100%;
    }

    &Links &ChildLink a:first-child {
      padding-top: 0px;
      border-bottom: none;
      padding-bottom: 16px;
      background: none;
    }

    &Links &ChildLink a:last-child {
      padding-bottom: 0px;
      border-bottom: none;
      background: none;
    }

    &Links {
      gap: 0;
      margin: 0 -25px;
      overflow: hidden;
      transition: all 0.5s ease;
      position: absolute;
      top: 56px;
      background: $color-white-0;

      .contactLink {
        display: block;
      }
    }

    .contactLinkMobile {
      display: none;
    }

    &Links a {
      width: 100%;
      padding: 18px 16px;
    }

    &Links &ChildLink a {
      padding-left: 0;
      padding-top: 8px;
      padding: 8px 8px 8px 0px;
    }
    .active {
      color: $color-green-0 !important;
    }

    .active {
      background-color: #d8ede2;
      color: $color-green-0;
      @include font-semi-bold(1.6rem);
    }

    .nonActive {
      background-color: transparent;
    }

    .contactActive {
      background-color: white;
    }
  }
}

.hidden {
  transform: translateY(-250%) !important;
  transition: all 0.5s ease-in-out;
}
