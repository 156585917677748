@import "./variables.scss";
@import "react-responsive-carousel/lib/styles/carousel.min.css";
// @import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&family=Figtree:wght@400;500;600;700&family=Open+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Figtree", sans-serif;
}

:root {
  --header-height: 1vh;
}

html,
body {
  font-size: 10px;
  min-height: unset;
  overflow: inherit;
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

.commonHeading h2 {
  font-size: 4rem;
  font-weight: 700;
  color: $color-blackCurrant-0;
  text-align: center;
}

.container {
  max-width: 1232px;
  margin: 0px auto;
  padding: 0px 16px;
}
