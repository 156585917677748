@import "/src/styles/common.scss";
@import "/src/styles/variables.scss";

.agriFarmPage {
  //  first section
  .agriFarmers,
  .agriGrowth,
  .agriEmpower,
  .partners {
    padding: 100px 0px;

    &Chart {
      background: $color-white-0;

      &Responsive {
        display: none;
      }
    }
  }

  label {
    display: none;
  }

  // second section
  .agriGrowth {
    background: var(--stroke, $color-OysterBay-0);

    &Heading {
      color: var(--text-dark, #052b17);
      text-align: center;
      @include font-bold($fs: 4rem);
      max-width: 862px;
      margin: 0 auto 56px;
    }

    &CardsWrap {
      @include d-flex();
      gap: 32px;
      align-items: normal;
      flex-wrap: wrap;
    }

    &Card {
      border-radius: 64px 8px;
      padding: 32px;
      background: var(--white, $color-white-0);
      flex: 0 0 23%;

      span {
        color: var(--primary, #009a4a);
        @include font-bold($fs: 4rem);
      }

      p {
        @include font-med($fs: 1.8rem);
        color: var(--text-dark, #052b17);
        line-height: 26px;
        margin-top: 8px;
      }
    }
  }

  // third section
  .agriEmpower {
    &Approach {
      @include d-flex($jc: normal);
      cursor: pointer;

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    &Image {
      flex-basis: 50%;
      overflow: hidden;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        object-fit: cover;
        height: auto;
        transition: all 0.5s ease;
      }
    }

    &CardName {
      padding: 0 40px;
      flex-basis: 50%;

      h3 {
        @include font-bold($fs: 2.4rem);
        margin-bottom: 16px;
        color: var(--text-medium, $color-green-1);
      }
    }

    p {
      @include font-reg($fs: 2rem);
      line-height: 32px;
      color: var(--text-light, $color-gray-3);
      max-width: 518px;
      white-space: pre-line;
      margin-bottom: 16px;
    }

    span {
      @include font-reg($fs: 2rem);
      line-height: 32px;
      color: var(--text-light, $color-gray-3);
      max-width: 518px;
    }
  }

  // fourth section
  .partners {
    background: $linear-gradient-0;

    &Network {
      @include d-flex($jc: center);
      text-align: center;
      gap: 32px;
    }

    &Logo {
      border-radius: 48.683px 6.085px;
      background: var(--stroke, $color-OysterBay-0);
      padding: 24px;
      flex: 0 0 29.9%;
      min-height: 190px;

      .domainLogo {
        width: 81px;
      }

      p {
        color: #000;
        @include font-reg($fs: 1.6rem);
        line-height: 26px;
        margin-top: 8px;
      }
    }
  }

  .titleContainer h1 {
    max-width: 842px;
  }

  // responsive
  @media (max-width: 1230px) {
    .agriGrowth {
      &Card {
        flex: 0 0 40%;
        flex-grow: 1;
      }
    }

    .agriEmpower {
      &Image {
        flex-basis: 50%;
      }
    }
  }

  @media (max-width: 992px) {
    // second section
    .agriGrowth,
    .agriFarmers,
    .agriEmpower,
    .partners {
      padding: 48px 0px;

      &Heading {
        @include font-bold($fs: 2.4rem);
        margin: 0 auto 32px;
      }

      &CardsWrap {
        gap: 24px;
        flex-direction: column;
      }

      &Card {
        border-radius: 40px 8px;
        padding: 24px;
        flex: 0 0 23%;
        min-height: 0;

        span {
          @include font-bold(3.2rem);
        }

        p {
          @include font-med(1.6rem);
          margin-top: 8px;
          line-height: 160%;
        }
      }
    }

    // third section
    .agriEmpower {
      &Approach {
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 24px;

        &:nth-child(odd) {
          flex-direction: unset;
        }

        &:last-child {
          margin: 0 0 16px 0;
        }
      }

      &Image {
        flex-basis: 100%;
      }

      &CardName {
        padding: 0;
        flex-basis: auto;

        h3 {
          @include font-bold(2rem);
          margin-bottom: 16px;
        }

        p {
          @include font-reg($fs: 1.4rem);
          max-width: none;
          line-height: 160%;
        }
        span {
          @include font-reg($fs: 1.4rem);
          line-height: 160%;
        }
      }
    }

    // fourth section
    .partners {
      &Network {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 24px;
      }

      &Logo {
        border-radius: 48.683px 6.085px;
        padding: 24px;
        width: 100%;
        flex-grow: 1;
        min-height: 0;

        p {
          color: #000;
          @include font-reg($fs: 1.6rem);
          line-height: 22px;
          margin-top: 8px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .agriFarmers {
      &Chart {
        &Desktop {
          display: none;
        }

        h2 {
          display: none;
        }

        label {
          display: block;
          color: var(--text-dark, $color-blackCurrant-0);
          text-align: center;
          @include font-bold(2.4rem);
          text-transform: capitalize;
          margin-bottom: 32px;
        }

        &Responsive {
          display: block;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .titleContainer h1 {
      @include font-bold(3.2rem);
      max-width: 100%;
      text-transform: capitalize;
    }
  }
}
