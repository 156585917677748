$color-white-0: #ffffff;

$color-black-0: #000000;
$color-black-1: #1a202c;
$color-blackCurrant-0: #052b17;

$color-gray-0: #b1b1b1;
$color-gray-1: #7c7c7c;
$color-gray-2: #6a6a6a;
$color-gray-3: #5f786b;
$color-gray-4: #edf7f2;
$color-gray-5: #bcd2c6;

$color-green-0: #009a4a;
$color-green-1: #254e39;
$color-green-2: #dcefe5;
$color-green-3: #ebfff4;
$color-green-4: #d8ede2;
$color-green-5: #aedac3;
$color-green-6: #edf3f0;
$color-green-7: #00803d;

$color-OysterBay-0: #d8ede2;
$color-OysterBay-1: #eaf5ef;

$color-blue-0: #6985f7;

$color-red-0: #f00;

$linear-gradient-0: linear-gradient(180deg, #eef1ef 0%, #f2f8f3 100%);
$linear-gradient-1: linear-gradient(
  91deg,
  #000 -10.08%,
  rgba(0, 0, 0, 0) 75.6%
);
$linear-gradient-2: linear-gradient(180deg, #f4f6f4 0%, #f8fbf9 100%);
$linear-gradient-3: var(
  --Primary-Gradiant,
  linear-gradient(180deg, #00cc1f 0%, #009a15 100%)
);

$color-rgba-0: rgba(255, 255, 255, 0.3);

// Font mixins
@mixin font-reg($fs: 1.6rem) {
  font-size: $fs;
  font-weight: 400;
}

@mixin font-med($fs: 1.6rem) {
  font-size: $fs;
  font-weight: 500;
}

@mixin font-semi-bold($fs: 1.6rem) {
  font-size: $fs;
  font-weight: 600;
}

@mixin font-bold($fs: 1.6rem) {
  font-size: $fs;
  font-weight: 700;
}

// Flex mixin
@mixin d-flex($jc: flex-start) {
  display: flex;
  align-items: center;
  justify-content: $jc;
}
