@import "src/styles/variables.scss";
@import "src/styles/common.scss";

.career {
  .commonHeading {
    color: $color-blackCurrant-0;
    @include font-bold(4rem);
    margin-bottom: 24px;
  }

  // abouCareer
  .aboutCareer,
  .careerOffer,
  .careerDifference,
  .careerInfo,
  .careerJobs {
    padding: 100px 0;

    &Para {
      color: var(--text-light, $color-gray-3);
      text-align: center;
      @include font-reg(1.6rem);
      line-height: 26px;
      max-width: 817px;
      margin: 0 auto;
    }
  }

  //   careerOffer
  .careerOffer {
    background: var(--Stroke, $color-OysterBay-0);

    &Team {
      @include d-flex(normal);
      gap: 56px;

      &Mobile {
        display: none;
      }
    }

    h3 {
      text-align: left;
    }

    p {
      color: var(--text-light, $color-gray-3);
      @include font-reg(1.8rem);
      line-height: 26px;
      max-width: 572px;
    }
  }

  // careerDifference
  .careerDifference {
    &Desktop {
      margin-top: 56px;
    }

    &Mobile {
      display: none;
    }
  }

  // careerInfo
  .careerInfo {
    &Page {
      border-radius: 12px;
      background: var(--Stroke, $color-OysterBay-0);
      padding: 40px;
      background-image: url("../../assets/images/careers/Union.svg");
      text-align: center;
      background-position: 100%;
      background-size: 104%;

      h2 {
        color: var(--text-dark, $color-blackCurrant-0);
        @include font-bold(3.2rem);
        text-transform: capitalize;
        margin-bottom: 24px;
      }

      p {
        color: var(--text-medium, $color-green-1);
        @include font-reg(1.6rem);
        width: 79%;
        margin: 0 auto;
        margin-bottom: 40px;
        line-height: 26px;
      }

      button {
        color: var(--White, $color-white-0);
        @include font-med(1.6rem);
        border: none;
        border-radius: 4px;
        background: var(--Primary, $color-green-0);
        cursor: pointer;
        padding: 10px 0px;

        &:hover {
          background: $color-green-7;
        }

        a {
          padding: 10px 24px;
          color: var(--White, $color-white-0);
        }
      }
    }
  }

  // accordion
  .careerJobs {
    h2 {
      color: $color-blackCurrant-0;
      text-align: center;
      @include font-bold(4rem);
      text-transform: capitalize;
      margin-bottom: 56px;
    }

    &Desktop {
      display: none;
    }

    .hideBorder {
      border-bottom: none;
    }

    &Title {
      @include d-flex(space-between);
      padding: 32px 0;
      cursor: pointer;

      &Head {
        color: var(--text-dark, $color-blackCurrant-0);
        @include font-semi-bold(2.4rem);
        text-transform: capitalize;
        width: 33.4%;
      }

      span {
        color: var(--text-medium, $color-green-1);
        @include font-med(1.6rem);
        white-space: nowrap;
      }
    }

    &Details {
      @include d-flex(space-between);
      gap: 40px;
      flex-basis: 44.3%;
    }

    &Places {
      @include d-flex(normal);
      gap: 8px;
      align-items: flex-start;
      flex-basis: 24%;
    }

    .grow {
      flex-basis: 33%;
    }

    &Contents {
      h5 {
        color: var(--text-medium, $color-green-1);
        @include font-med(1.8rem);
        line-height: 26px;
        margin: 24px 0 16px;

        &:first-child {
          margin-top: 0;
        }
      }

      p {
        color: var(--text-light, $color-gray-3);
        @include font-reg(1.6rem);
        line-height: 26px;
        max-width: 1102px;
      }

      ul {
        list-style-type: disc;
        padding-left: 19px;

        li {
          color: var(--text-light, $color-gray-3);
          @include font-reg(1.6rem);
          line-height: 26px;
          margin-bottom: 8px;

          &:last-child {
            margin: 0;
          }
        }
      }

      button {
        border-radius: 4px;
        background: $color-green-0;

        padding: 10px 0px;
        @include font-med(1.6rem);
        border: none;
        margin-top: 24px;
        cursor: pointer;

        &:hover {
          background: $color-green-7;
        }

        a {
          color: var(--White, $color-white-0);
          padding: 10px 24px;
        }
      }

      border-bottom: 1px solid $color-gray-5;
      padding: 0 0 32px;
    }
  }

  //   responsive
  @media (max-width: 992px) {
    .commonHeading {
      @include font-bold(2.4rem);
      margin-bottom: 16px;
    }

    // responsiveSection
    .aboutCareer,
    .careerOffer,
    .careerDifference,
    .careerInfo,
    .careerJobs {
      padding: 48px 0;

      &Para {
        @include font-reg(1.4rem);
        line-height: 160%;
        max-width: 100%;
      }
    }

    // careerJobs
    .careerJobs {
      &Desktop {
        display: block;
      }

      &Mobile {
        display: none;
      }
    }

    //   careerOffer
    .careerOffer {
      &Header {
        margin-bottom: 24px;
      }

      &Team {
        flex-direction: column;
        gap: 0;

        div {
          flex-basis: 100%;
        }

        h3 {
          text-align: center;
        }

        &Mobile {
          display: block;
          width: 100%;
        }

        &Desktop {
          display: none;
        }
      }

      p {
        @include font-reg(1.4rem);
        line-height: 160%;
        margin-top: 24px;
        max-width: 100%;
      }
    }

    // careerInfo
    .careerInfo {
      &Page {
        border-radius: 12px;
        padding: 24px;
        background-position: 29% 549%;
        background-size: auto;
        background-repeat: no-repeat;

        h2 {
          @include font-bold(2.4rem);
          margin-bottom: 16px;
        }

        p {
          @include font-reg(1.4rem);
          width: 97%;
          margin-bottom: 16px;
          line-height: 160%;
          text-align: center;
        }
      }
    }

    // accordion
    .careerJobs {
      h2 {
        @include font-bold(2.4rem);
        margin-bottom: 24px;
      }

      .hideBorder {
        border-bottom: none;
        // padding: 0;
      }

      &Title {
        flex-direction: column;
        padding: 24px 0;
        align-items: normal;

        &:nth-child(2) {
          padding: 0 0 24px;
        }

        &Head {
          @include font-semi-bold(2rem);
          @include d-flex(space-between);
          width: 100%;
          margin-bottom: 14px;
        }

        span {
          @include font-med(1.4rem);
        }
      }

      &Details {
        @include d-flex(space-between);
        flex-basis: auto;
      }

      &Contents {
        h5 {
          @include font-med(1.6rem);
          line-height: normal;
          margin: 0 0 16px;
        }

        p {
          @include font-reg(1.4rem);
          line-height: 160%;
          max-width: 1102px;
        }

        ul {
          list-style-type: disc;
          padding-left: 19px;

          li {
            color: var(--text-light, $color-gray-3);
            @include font-reg(1.6rem);
            line-height: 26px;
            margin-bottom: 8px;

            &:last-child {
              margin: 0;
            }
          }
        }

        button,
        a {
          border-radius: 4px;
          background: $color-green-0;
          color: var(--White, $color-white-0);
          padding: 10px 24px;
          @include font-med(1.6rem);
          border: none;
          margin-top: 24px;
          cursor: pointer;
        }

        border-bottom: 1px solid $color-gray-5;
        padding: 0 0 32px;
      }
    }
  }

  @media (max-width: 768px) {
    // careerDifference
    .careerDifference {
      &Desktop {
        display: none;
      }

      &Mobile {
        display: block;
        margin: 56px auto 0;
      }
    }
  }
}
