@import "/src/styles/common.scss";
@import "/src/styles/variables.scss";

.dairyFarm {
  .commonHeading {
    color: var(--text-dark, $color-blackCurrant-0);
    @include font-bold(4rem);
    margin: 0 auto 56px;
    max-width: 865px;
  }

  // secondSection
  .dairyFarming,
  .dairyGrowth,
  .partners {
    padding: 100px 0px;

    &Responsive {
      img {
        display: none;
      }

      &Content {
        color: var(--text-medium, $color-green-1);
        @include font-semi-bold(1.8rem);
        line-height: 26px;
        border-radius: 8px;
        background: var(--Stroke, $color-OysterBay-0);
        padding: 24px;
        margin-top: 32px;
        text-align: center;
        max-width: 957px;
        margin: 56px auto 0;
      }
    }
  }

  // thirdSection
  .dairyGrowth {
    background: var(--stroke, $color-OysterBay-0);

    &CardsWrap {
      @include d-flex();
      gap: 32px;
      align-items: normal;
      flex-wrap: wrap;
    }

    &Card {
      border-radius: 64px 8px;
      padding: 32px;
      background: var(--white, $color-white-0);
      flex: 0 0 23%;

      span {
        color: var(--primary, #009a4a);
        @include font-bold($fs: 4rem);
      }

      p {
        @include font-med($fs: 1.8rem);
        color: var(--text-dark, #052b17);
        line-height: 26px;
        margin-top: 8px;
      }
    }
  }

  //fourthSection
  .partners {
    background: $color-white-0;

    h2 {
      max-width: fit-content;
    }

    &Network {
      @include d-flex($jc: center);
      flex-wrap: wrap;
      align-items: stretch;
      text-align: center;
      gap: 40px;
    }

    &Logo {
      border-radius: 48.683px 6.085px;
      background: var(--stroke, $color-OysterBay-0);
      padding: 24px;
      flex: 0 0 31.1%;
      @include d-flex(flex-start);
      flex-direction: column;

      h3 {
        color: var(--text-light, $color-gray-3);
        text-align: center;
        @include font-reg(1.6rem);
        line-height: 26px;
        margin: 8px 0;
        max-width: 310px;
        flex-grow: 1;
      }

      p {
        color: #254e39;
        @include font-med(1.6rem);
      }
    }
  }

  // responsive
  @media (max-width: 1230px) {
    .dairyGrowth {
      &Card {
        flex: 0 0 40%;
        min-height: 198px;
        flex-grow: 1;
      }
    }
  }

  @media (max-width: 992px) {
    .commonHeading {
      @include font-bold(2.4rem);
      margin-bottom: 32px;
      max-width: 100%;
    }

    .dairyFarming,
    .dairyGrowth,
    .partners {
      padding: 48px 0px;

      &Desktop {
        display: none;
      }

      &Responsive {
        img {
          display: block;
          width: 100%;

          &Content {
            color: var(--text-medium, $color-green-1);
            @include font-semi-bold(1.8rem);
            line-height: 26px;
            border-radius: 8px;
            background: var(--Stroke, $color-OysterBay-0);
            padding: 24px;
            margin-top: 32px;
          }
        }
      }

      &Content {
        color: var(--text-medium, $color-green-1);
        @include font-semi-bold(1.8rem);
        line-height: 26px;
        border-radius: 8px;
        background: var(--Stroke, $color-OysterBay-0);
        padding: 24px;
        margin-top: 32px;
      }

      &CardsWrap {
        gap: 20px;
        flex-direction: column;
      }

      &Card {
        border-radius: 40px 8px;
        padding: 24px;
        flex: 0 0 23%;
        min-height: inherit;

        span {
          @include font-bold($fs: 3.2rem);
        }

        p {
          @include font-med($fs: 1.6rem);
          margin-top: 8px;
        }
      }
    }

    // fourthSection
    .partners {
      &Network {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 22px;
      }

      &Logo {
        border-radius: 28.683px 5.085px;
        padding: 24px;
        width: 100%;
        flex-grow: 1;

        h3 {
          max-width: 100%;
        }

        p {
          color: $color-gray-3;
          @include font-reg($fs: 1.4rem);
          line-height: 160%;
          margin-top: 0;
          min-height: 0;
        }
      }
    }
  }
}
