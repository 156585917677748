@import "/src/styles/variables.scss";
@import "react-responsive-carousel/lib/styles/carousel.min.css";

.dairy {
  .bannerContents {
    max-width: 879px;
  }
}

.agriSuccess {
  .bannerContents {
    max-width: 879px;
  }
}

.selected {
  z-index: 99 !important;
}

.banner {
  height: calc(100vh - 72px);
  position: relative;
  margin-top: 72px;

  &Background {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &IconImage {
    padding-bottom: 40px;
    max-width: 600px;
    height: 100%;
  }

  &MobImage {
    display: none;
  }

  &Background img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: top right;
  }

  &Container {
    @include d-flex();
    height: calc(100% - 91px);
    margin: 0px auto;
    padding: 0px 16px;
    max-width: 1200px;
  }

  &Contents {
    color: $color-white-0;
    max-width: 773px;

    h2 {
      @include font-bold($fs: 4.8em);
      margin-bottom: 16px;
      text-align: left;
      line-height: 67.2px;
    }

    p {
      @include font-reg($fs: 2rem);
      margin-bottom: 40px;
      text-align: left;
      line-height: 32px;
      opacity: 0.7;
      max-width: 773px;
    }
  }

  .MuiLinearProgress-root {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  &Button {
    @include d-flex($jc: center);
    padding: 10px 24px;
    border-radius: 4px;
    @include font-med($fs: 1.6rem);
    color: $color-green-0;
    border: none;
    gap: 10px;
    cursor: pointer;
  }

  &Button span {
    flex-shrink: 0;
  }

  &Button:hover {
    background: $color-green-0;
    color: white;

    svg {
      transform: translate(4px, -4px);
      transition: all 0.2s ease;
      path {
        stroke: white;
      }
    }
  }

  &Tabs {
    max-width: 1300px;
    margin: 0px auto;
    @include d-flex($jc: space-between);
  }

  .carousel .control-dots {
    border-top: 1px solid #006b2d;
  }

  &TabsContent[value="3"] {
    border-right: none;
  }

  &TabsContent {
    @include d-flex($jc: center);
    text-align: left;
    position: relative;
    padding: 24px 16px;
    width: 25%;
    border-right: 1px solid #006b2d;
    cursor: pointer;

    h5 {
      @include font-med($fs: 1.6rem);
      color: $color-gray-0;
      line-height: 19.2px;
      margin-bottom: 4px;
    }

    p {
      @include font-reg($fs: 1.6rem);
      color: $color-gray-1;
      line-height: 28px;
    }
  }

  &SwipeContainer {
    display: none !important;
    position: absolute;
    width: 100%;
    justify-content: center;
    bottom: 46px;
    color: #ffff;
    font-size: 12px;
  }

  &Swipe {
    @include d-flex($jc: center);
    gap: 4px;
  }

  // responsive
  @media screen and (max-width: 992px) {
    height: calc(100vh - 56px);
    margin-top: 56px;

    &IconImage {
      padding-bottom: 32px;
    }

    &Tabs {
      display: none;
    }

    &Background {
      height: 100vh;
    }

    &Background img {
      height: 100%;
    }

    &TabsContent {
      display: none;
    }

    .carousel .carousel-status {
      display: block !important;
      z-index: 1;
    }

    &SwipeContainer {
      display: flex !important;
    }
  }

  //responsive
  @media screen and (max-width: 992px) {
    display: block;

    &Contents {
      padding-top: 80px;
      padding-bottom: 20px;

      h2 {
        @include font-bold($fs: 3.2rem);
        line-height: 40px;
        margin-bottom: 8px;
        //To Do max-width: 310px;
      }

      p {
        @include font-reg($fs: 1.6rem);
        line-height: 28px;
        margin-bottom: 32px;
      }
    }

    &Container {
      height: calc(100vh - 88px);
      align-items: flex-start;
    }

    &Button {
      padding: 11px 20px;
      @include font-med($fs: 1.4rem);
      line-height: 17px;
    }

    .carousel-root {
      height: 100%;
    }

    .progress-value {
      height: 14px;
    }
  }

  @media screen and (max-width: 576px) {
    &MobImage {
      display: block !important;
    }

    &DesktopImage {
      display: none !important;
    }
  }
}

//carousel
.slider-wrapper {
  height: 100%;
}

.carousel-root {
  height: 100%;
}

.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  list-style: none;
  width: 100%;
}

.carousel-slider {
  //TO DO height: calc(100vh - 71px);
  height: 100%;
}

.control-dots {
  display: flex;
}

.carousel-status span {
  @include font-bold($fs: 1.6rem);
  color: #c4c4c4;
}

.carousel-status span .currentItem {
  @include font-bold($fs: 2.4rem);
  color: $color-white-0;
}

.carousel .carousel-status {
  display: none !important;
  bottom: 40px !important;
  top: initial;
  right: 40px !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.carousel .control-dots {
  margin: 0 !important;
}

.progress {
  position: absolute;
  width: 100%;
  top: -2px;
  left: 0;
}

.resp {
  display: none;
}

.progress-value {
  animation: load 4.5s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  background: linear-gradient(270deg, #b6d814 0%, #b5c17c 70.83%);
  height: 3px;
}

@keyframes load {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.carousel .slider-wrapper {
  position: relative;
  z-index: 1 !important;
}

.control-dots {
  z-index: 2 !important;
}
