@import "src/styles/variables.scss";
@import "src/styles/common.scss";

.diary {
  // commonHeading
  .techSolutionHeading {
    color: var(--text-dark, $color-blackCurrant-0);
    @include font-bold(4rem);
    text-align: center;
    max-width: 799px;
    margin: 0 auto 56px;
  }

  .farming {
    padding: 100px 0;

    &HeaderText {
      color: var(--text-dark, $color-blackCurrant-0);
      @include font-bold(4rem);
      text-align: center;
      max-width: 799px;
      margin: 0 auto 56px;
    }

    // diaryFarming
    &BgColor {
      background: $color-OysterBay-1;
    }

    &Header {
      @include d-flex($jc: center);
      gap: 24px;

      .active {
        border: 1px solid $color-green-0;
        border-radius: 20px;
        color: $color-green-0;
      }

      li {
        @include font-med($fs: 1.8rem);
        line-height: 26px;
        color: $color-gray-3;
        padding: 4px 16px;
        cursor: pointer;
      }

      &TabContent {
        @include d-flex($jc: normal);
        gap: 56px;
        align-items: normal;
        margin-top: 40px;

        img {
          object-fit: cover;
          width: 572px;
          height: 429px;
        }

        p {
          color: var(--text-medium, $color-green-1);
          @include font-bold(2.4rem);
          margin-bottom: 24px;
          text-transform: capitalize;
        }

        ul {
          padding-left: 17px;

          li {
            list-style-type: disc;
            color: var(--text-light, $color-gray-3);
            @include font-reg(1.6rem);
            line-height: 26px;
            margin-bottom: 16px;
          }
        }

        &Mobile {
          display: none;
        }
      }
    }

    // diaryEcoSystem
    &Approach {
      @include d-flex($jc: normal);
      cursor: pointer;

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      &Image {
        flex-basis: 50%;
        overflow: hidden;
        cursor: pointer;

        img {
          display: block;
          width: 100%;
          object-fit: cover;
          transition: all 0.5s ease;
        }
      }

      &CardName {
        padding: 0 40px;
        flex-basis: 50%;

        h5 {
          @include font-bold($fs: 2.4rem);
          margin-bottom: 16px;
          color: var(--text-medium, $color-green-1);

          &:nth-child(4) {
            margin-top: 12px;
          }
        }
      }

      p {
        @include font-reg($fs: 2rem);
        line-height: 32px;
        color: var(--text-light, $color-gray-3);
        max-width: 518px;
        white-space: pre-line;
      }

      &Contents {
        ul {
          padding-left: 19px;

          li {
            list-style-type: disc;
            color: var(--text-light, $color-gray-3);
            @include font-reg(1.6rem);
            line-height: 26px;
            margin-bottom: 16px;
            max-width: 494px;
          }
        }
      }
    }

    // farmersIncome
    &Background {
      background: var(--Stroke, $color-OysterBay-0);
    }

    &CardsWrap {
      @include d-flex();
      gap: 32px;
      align-items: normal;
      flex-wrap: wrap;
    }

    &Card {
      border-radius: 64px 8px;
      padding: 32px;
      background: var(--white, $color-white-0);
      flex: 0 0 31.5%;

      &Range {
        color: var(--primary, $color-green-0);
        @include font-bold($fs: 4rem);
      }

      &Description {
        @include font-med($fs: 1.8rem);
        color: var(--text-dark, $color-blackCurrant-0);
        line-height: 26px;
      }

      &Title {
        color: var(--text-light, $color-gray-3);
        @include font-reg(1.6rem);
        line-height: 26px;
        margin: 8px 0;
      }
    }
  }

  // responsive
  @media (max-width: 1230px) {
    .farming {
      &Card {
        flex: 0 0 31%;
        flex-grow: 1;
      }
    }
  }

  @media (max-width: 1100px) {
    // diaryFarming
    .farming {
      &Header {
        @include d-flex($jc: center);
        gap: 4px;

        li {
          @include font-med(1.4rem);
          line-height: 16px;
          padding: 4px 8px;
          text-align: center;
        }

        &TabContent {
          flex-direction: none;
          gap: 36px;
          align-items: normal;
          margin-top: 20px;

          img {
            object-fit: cover;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .techSolutionHeading {
      max-width: 100%;
      @include font-bold(2.4rem);
      text-align: center;
      margin: 0 auto 32px;
    }

    .farming {
      padding: 48px 0;

      &HeaderText {
        max-width: 100%;
        @include font-bold(2.4rem);
        text-align: center;
        margin: 0 auto 24px;
      }

      &Header {
        &TabContent {
          flex-direction: column-reverse;
          gap: 36px;
          align-items: normal;
          margin-top: 20px;

          img {
            width: 100%;
            height: auto;
          }

          &Desktop {
            display: none;
          }

          &Mobile {
            display: block;
            width: 100%;
          }

          ul {
            padding-left: 17px;
            margin-top: 16px;

            li {
              max-width: 100%;
              @include font-reg(1.4rem);
              line-height: 160%;

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }

      // diaryEcoSystem
      &Approach {
        flex-wrap: wrap;
        flex-direction: column;

        &:nth-child(odd) {
          flex-direction: column;
        }

        &Image {
          flex-basis: auto;
          width: 100%;
          height: auto;
        }

        &CardName {
          padding: 16px 0;

          h5 {
            @include font-bold($fs: 2rem);
            margin-bottom: 12px;
          }
        }

        p {
          @include font-reg($fs: 1.8rem);
          line-height: 30px;
        }

        &Contents {
          ul {
            padding-left: 20px;

            li {
              line-height: 22px;
              margin-bottom: 12px;
              max-width: 100%;

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }

      // farmersIncome
      &CardsWrap {
        gap: 20px;
        flex-direction: column;
      }

      &Card {
        border-radius: 40px 8px;
        padding: 24px;
        flex: 0 0 23%;
        min-height: 0;

        &Range {
          @include font-bold(3.2rem);
        }

        &Description {
          @include font-med(1.6rem);
          line-height: 26px;
        }

        &Title {
          @include font-reg(1.6rem);
          line-height: 26px;
          margin: 16px 0 8px;
        }
      }
    }
  }
}
