@import "/src/styles/common.scss";
@import "/src/styles/variables.scss";

.blogs {
  // blogsCard
  .blogPage,
  .blogNews,
  .blogProfile {
    padding: 100px 0px;

    &Articles {
      @include d-flex($jc: center);
      gap: 40px;
      row-gap: 56px;
      flex-wrap: wrap;
    }

    &Card {
      border-radius: 8px;
      border: 1px solid var(--stroke, $color-OysterBay-0);
      background: #fff;
      max-width: 373px;
      transition: all 0.5s ease;
      cursor: pointer;

      &:hover {
        transition: all 0.5s ease;
        box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.12);
      }

      &:hover {
        .blogPageImg {
          img {
            transform: scale(1.1);
          }
        }
      }
    }

    &Img {
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0 0;

      img {
        display: block;
        width: 100%;
        object-fit: cover;
        height: 100%;
        transition: all 0.5s ease;

        &:hover {
          transform: scale(1.1);
          border-radius: 8px;
          box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }

    &Content {
      padding: 16px 24px 24px;

      p {
        color: var(--text-medium, $color-green-1);
        @include font-med(1.8rem);
        line-height: 26px;
        width: 94%;
        margin-bottom: 16px;
      }
    }

    &Author {
      @include d-flex($jc: space-between);
      align-items: flex-end;

      &Detail {
        @include d-flex($jc: normal);
        gap: 16px;

        h4 {
          color: var(--text-medium, $color-gray-3);
          @include font-med(1.4rem);
          line-height: 16px;
          margin-bottom: 4px;
          white-space: nowrap;
        }

        p {
          color: var(--text-light, $color-gray-3);
          @include font-reg(1.4rem);
          line-height: 16px;
          margin: 0;
        }
      }

      &Details {
        @include d-flex($jc: normal);
        gap: 16px;
        margin-bottom: 56px;

        h4 {
          color: var(--text-medium, $color-gray-3);
          @include font-med(1.4rem);
          line-height: 16px;
          margin-bottom: 4px;
          white-space: nowrap;
        }

        p {
          color: var(--text-light, $color-gray-3);
          @include font-reg(1.4rem);
          line-height: 16px;
          margin: 0;
        }
      }
    }

    &Reload {
      @include d-flex($jc: normal);
      gap: 4px;

      img {
        flex-basis: 5.1%;
      }

      p {
        margin: 0;
        color: var(--text-light, $color-gray-3);
        @include font-reg(1.4rem);
        line-height: 16px;
        white-space: nowrap;
      }
    }

    &ViewMore {
      @include d-flex($jc: center);
      margin-top: 56px;
      align-items: flex-end;
      gap: 6px;
      white-space: nowrap;

      p {
        color: var(--text-dark, $color-blackCurrant-0);
        @include font-med(1.6rem);
        cursor: pointer;
      }

      &:hover {
        p {
          color: $color-green-0;
        }

        svg {
          path {
            fill: $color-green-0;
          }
        }
      }
    }

    // second section
    &Contents {
      padding: 24px;

      p {
        color: var(--text-light, $color-gray-3);
        @include font-reg(1.6rem);
        line-height: 26px;
        margin-bottom: 24px;
      }
    }
  }

  // blogNews
  .blogNews {
    background: $linear-gradient-0;

    .heading {
      color: var(--text-dark, $color-blackCurrant-0);
      @include font-bold(4rem);
      text-align: center;
      margin-bottom: 56px;
    }
  }

  .brandLogo {
    width: 173px;
  }

  // blogArticle
  .blogArticle {
    padding: 100px 16px 0;
    max-width: 930px;
    margin: 0 auto;

    h2 {
      color: var(--text-dark, $color-blackCurrant-0);
      @include font-bold(4rem);
      margin-bottom: 24px;
    }

    &Img {
      overflow: hidden;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        transition: all 0.5s ease;
        border-radius: 8px;
      }
    }

    &Author {
      img {
        margin: 0;
      }
    }

    &Description {
      padding-bottom: 100px;
      @include d-flex($jc: normal);
      gap: 32px;
      align-items: normal;
      margin-top: 56px;

      p {
        color: var(--text-light, $color-gray-3);
        @include font-reg(1.6rem);
        line-height: 26px;
        text-align: justify;
        margin-bottom: 16px;
        max-width: 796px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &SubHeading {
      h3 {
        color: var(--text-medium, $color-green-1);
        text-align: justify;
        @include font-bold(2.4rem);
        text-transform: capitalize;
        margin: 24px 0 16px;
      }
    }

    &SocialMedia {
      @include d-flex($jc: normal);
      flex-direction: column;
      gap: 400px;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      height: fit-content;

      p {
        margin: 0 0 8px;
        color: var(--text-dark, $color-blackCurrant-0);
        @include font-med(1.8rem);
        line-height: 26px;
      }

      img {
        margin: 0;
        height: auto;
        cursor: pointer;
        width: 31px;
      }

      &Logo {
        @include d-flex($jc: normal);
        gap: 16px;
        flex-direction: column;
        padding: 0 24px;
      }

      &Loader {
        position: relative;
        .greenArrow {
          position: absolute;
          left: 32%;
          top: 32%;
        }
        max-width: 44px;
        img {
          width: 44px;
        }
      }
    }
  }

  // profileCard
  .blogProfile {
    background: $linear-gradient-2;

    h3 {
      color: var(--text-dark, $color-blackCurrant-0);
      margin-bottom: 56px;
      @include font-bold(4rem);
      text-align: center;
    }
  }

  // blogsBanner
  .blogBanner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    height: 100vh;

    &Content {
      position: relative;
      height: 100%;
    }

    &NewContainer {
      height: 100%;
      max-width: 1232px;
    }

    &Header {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-width: 796px;
      width: 100%;

      span {
        border-radius: 4px;
        background: $color-rgba-0;
        color: $color-white-0;
        @include font-med(1.6rem);
        padding: 4px 8px;
      }

      h1 {
        color: var(--White, $color-white-0);
        font-family: Figtree;
        @include font-bold(4rem);
        margin: 24px 0 16px;
      }

      p {
        color: $color-white-0;
        @include font-reg(1.6rem);
        line-height: 26px;
      }
    }

    &Author {
      &Details {
        @include d-flex($jc: normal);
        gap: 16px;
        margin: 16px 0;

        h4 {
          color: var(--text-medium, $color-white-0);
          @include font-med(1.4rem);
          line-height: 16px;
          margin-bottom: 4px;
          white-space: nowrap;
        }

        p {
          color: var(--text-light, $color-gray-5);
          @include font-reg(1.4rem);
          line-height: 16px;
          margin: 0;
        }
      }
    }

    &Reload {
      @include d-flex($jc: normal);
      gap: 4px;
      margin-bottom: 24px;

      p {
        margin: 0;
        color: var(--text-light, $color-gray-5);
        @include font-reg(1.4rem);
        line-height: 16px;
        white-space: nowrap;
      }
    }

    button {
      border-radius: 4px;
      background: var(--Primary, $color-green-0);
      padding: 10px 24px;
      color: $color-white-0;
      @include font-med(1.6rem);
      border: none;
      cursor: pointer;

      &:hover {
        background: $color-green-7;
      }
    }
  }

  // responsive
  @media (max-width: 992px) {
    .blogPage,
    .blogNews,
    .blogProfile {
      padding: 48px 0px;

      &Articles {
        flex-direction: column;
        row-gap: 24px;
      }

      &Author {
        gap: 4px;

        &Details {
          margin: 24px 0;
          gap: 16px;

          h4 {
            @include font-med(1.6rem);
            margin-bottom: 4px;
          }

          p {
            color: var(--text-light, #5f786b);
            @include font-reg(1.4rem);
            line-height: 16px;
            margin: 0;
            white-space: nowrap;
          }
        }
      }

      &ViewMore {
        margin-top: 32px;
      }

      &Content {
        padding: 16px 12px;

        p {
          @include font-med(1.6rem);
          line-height: 18px;
        }
      }

      &Reload {
        p {
          margin: 0;
          @include font-reg(1.4rem);
          line-height: 16px;
        }
      }

      &Contents {
        padding: 16px 12px;

        p {
          @include font-reg(1.4rem);
          line-height: 26px;
          margin-bottom: 24px;
          max-width: 100%;
        }
      }
    }

    // blogNews
    .blogNews {
      .heading {
        @include font-bold(2.4rem);
        margin-bottom: 32px;
      }

      .brandLogo {
        width: 173px;
      }
    }

    // blogArticle
    .blogArticle {
      padding: 48px 16px;

      h2 {
        @include font-bold(2.4rem);
        margin-bottom: 24px;
        padding-top: 48px;
      }

      img {
        min-height: 0px;
      }

      &Author {
        img {
          margin: 0;
          width: 40px;
          min-height: 40px;
        }
      }

      &Description {
        flex-direction: column;
        gap: 32px;
        margin-top: 24px;

        p {
          @include font-reg(1.4rem);
          line-height: 160%;
          text-align: justify;
          margin-bottom: 16px;
        }
      }

      &SubHeading {
        h3 {
          text-align: justify;
          @include font-bold(2rem);
          margin: 24px 0 16px;
        }
      }

      &SocialMedia {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0;

        p {
          margin: 0;
          @include font-med(1.8rem);
          line-height: 26px;
        }

        img {
          margin: 0;
          min-height: 0;
          cursor: pointer;
        }

        &Logo {
          flex-direction: initial;
          gap: 10px;
          padding: 0;
          align-items: flex-start;
        }

        &Loader {
          display: none;
          img {
            width: 31px;
            display: none;
          }
        }
      }
    }

    // profileCard
    .blogProfile {
      background: $linear-gradient-2;

      h3 {
        margin-bottom: 32px;
        @include font-bold(2.4rem);
      }
    }

    // blogBanner
    .blogBanner {
      &Header {
        max-width: 100%;

        span {
          border-radius: 4px;
          @include font-med(1.5rem);
          padding: 4px 8px;
        }

        h1 {
          @include font-bold(3.2rem);
          margin: 16px 0 12px;
        }

        p {
          @include font-reg(1.4rem);
          line-height: 160%;
          margin-bottom: 12px;
        }
      }

      &Author {
        &Details {
          margin: 14px 0 12px;
          gap: 16px;

          h4 {
            @include font-med(1.6rem);
            white-space: unset;
          }

          p {
            @include font-reg(1.4rem);
            line-height: 16px;
            margin: 0;
          }

          img {
            width: 40px;
          }
        }
      }

      &Reload {
        margin-bottom: 16px;

        p {
          margin: 0;
          @include font-reg(1.4rem);
          line-height: 160%;
        }
      }
    }
  }
}
