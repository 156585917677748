@import "/src/styles/common.scss";
@import "/src/styles/variables.scss";

.privacy-policy {
  background-color: #f9f9f9;
  padding: 80px 0;
  text-align: center;
}

.privacy-policy-container {
  max-width: 1000px;
  margin: 0 auto;
}

.privacy-policy-heading h1 {
  color: #052b17;
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 40px;
}

.privacy-policy-content {
  margin-top: 40px;
  padding: 16px;
  text-align: left;

  h2 {
    font-size: 1.8rem;
    color: #555;
    margin-top: 20px;
  }

  .subsection {
    font-size: 1.4rem;
    color: #555;
    margin-top: 16px;
    margin-left: 20px;
  }

  .subsection-content {
    margin-left: 20px; 
  }

  p {
    font-size: 1.6rem;
    color: #777;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}