@import "/src/styles/common.scss";
@import "/src/styles/variables.scss";

.product {
  padding: 15rem 0 4rem;

  .commonHeading {
    margin-bottom: 3rem;

    h2 {
      font-size: 3.2rem;
      color: #333;
      text-align: center;
    }
  }

  .productContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;

    .eachProduct {
      background: #fff;
      border-radius: 1rem;
      overflow: hidden;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      display: flex;
      flex-direction: column;

      &:hover {
        transform: translateY(-5px);
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: contain;
        background: #f8f9fa;
        padding: 1rem;
      }

      .product-content {
        padding: 2rem 2rem 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .agri {
          margin-bottom: 1.5rem;

          .agriInputs {
            display: inline-block;
            padding: 0.5rem 1.2rem;
            background: #e6f4ed;
            color: #009a4a;
            border-radius: 2rem;
            font-size: 1.3rem;
            font-weight: 500;
            letter-spacing: 0.02em;
          }
        }

        .content {
          margin-bottom: 2rem;
          flex-grow: 1;
          
          p {
            font-size: 1.6rem;
            line-height: 1.6;
            color: #444;
            margin-bottom: 1.5rem;

            span {
              font-weight: 600;
              color: #333;
              font-size: 1.8rem;
            }
          }
        }

        .button-group {
          display: flex;
          margin: 0 -2rem;
          border-top: 1px solid #eee;

          .btn {
            flex: 1;
            padding: 1.2rem;
            font-size: 1.4rem;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            transition: all 0.2s ease;
            font-weight: 500;
            letter-spacing: 0.01em;

            &:hover {
              opacity: 0.9;
            }

            &:active {
              opacity: 1;
            }
          }

          .btn-buynow {
            background: #009a4a;
            color: #fff;
            border-radius: 0;

            svg {
              display: none; // Hide arrow permanently
            }

            &:hover {
              background: #008540;
            }
          }

          .btn-details {
            background: #fff;
            color: #009a4a;
            border-left: 1px solid #eee;
            border-radius: 0;

            svg {
              width: 1.6rem;
              height: 1.6rem;
              transition: transform 0.3s ease;
              path {
                stroke: currentColor;
                stroke-width: 2;
              }
            }

            &:hover {
              background: #f8f9fa;

              svg {
                transform: translateX(4px); // Animate arrow on hover
              }
            }
          }
        }
      }
    }
  }

  .productForm{
    background: #FFFFFF;
    border-radius: 30px;
    padding: 50px;
  }

  .conversationContactFormInput{
    gap: 20px;
  }

  .productInput {
    width: 100%;
    position: relative;

    select {
      width: 100%;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 14.5px;
      left: 14px;
      color: #000;
      font-size: 14px;
    }

    &:focus-within {
      input {
        padding-left: 40px;
      }

      &::before {
        content: "+91";
      }
    }
  }

  .conversationContactFormInput div {
    width: 100%;
  }

  .conversationContactFormInput select{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 16px;
    padding: 14px 15px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d8ede2;
    outline: 0;
    background: #ffffff;
  }

  .productFormHeading {
    span {
      @include font-bold($fs: 2.6rem);
      color: #052b17;
    }
  
    p {
      padding: 8px 0 24px;
      @include font-reg($fs: 1.4rem);
      line-height: 160%;
      color: #052b17;
      padding-bottom: 20px;
    }
  }

  #c6 {
    color: #7D1221;
    text-align: justify;
    font-family: 'Figtree', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #max{
    color: #0C704F;
    font-family: 'Figtree', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #carbon{
    color: #75B943;
    text-align: justify;
    font-family: 'Figtree', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #lites{
    color: #000;
    font-family: 'Figtree', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #soil{
    color: #CE2028;
    text-align: justify;
    font-family: 'Figtree', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media only screen and (max-width: 585px) {
    .eachProduct {
      display: block;
      text-align: center;
      padding: 10px;
    }
    .product-content .content {
      font-size: 16px;
    }
    .productForm {
      background: #FFFFFF;
      border-radius: 15px;
      padding: 10px;
    }
  }
}