@import "src/styles/variables.scss";
@import "src/styles/common.scss";

.footer {
  padding: 64px 0 32px;

  &Container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;
    border-bottom: 1px solid $color-green-5;
  }

  &Logos {
    img {
      cursor: pointer;
    }

    p {
      @include font-reg($fs: 1.6rem);
      color: $color-blackCurrant-0;
      max-width: 384px;
      padding: 16px 0 24px;
      line-height: 160%;
    }
  }

  &SocialLinks {
    display: flex;
    gap: 24px;
    cursor: pointer;

    &Size {
      width: 32px;
      height: 32px;
      background: $color-green-4;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &Size:hover {
      background: $color-green-0;
    }

    &LinkedIcon:hover {
      svg {
        path {
          fill: $color-white-0;
        }
      }
    }
    &FaceBookIcon:hover {
      svg {
        path {
          fill: $color-white-0;
        }
      }
    }

    &InstaIcon:hover {
      svg {
        path {
          fill: $color-white-0;
        }
      }
    }
  }

  &NavTab {
    ul {
      @include font-med($fs: 1.6rem);
      padding-bottom: 24px;
      cursor: pointer;

      a {
        color: $color-blackCurrant-0;
      }
    }

    :hover {
      color: #009a4a;
    }

    li {
      list-style-type: none;
      padding-bottom: 24px;

      a {
        color: $color-blackCurrant-0;
        @include font-med($fs: 1.6rem);
        cursor: pointer;

        &:hover {
          color: #009a4a;
        }
      }
    }

    li:hover {
      color: #009a4a;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }

  &NavLinks {
    ul {
      @include font-med($fs: 1.6rem);
      color: $color-blackCurrant-0;
      padding-bottom: 24px;
    }

    li {
      list-style-type: none;
      padding-bottom: 16px;
      @include font-reg($fs: 1.6rem);

      a {
        color: $color-gray-3;
        cursor: pointer;

        &:hover {
          color: #009a4a;
        }
      }
    }

    li:last-child {
      padding-bottom: 0;
    }
  }

  &Strips {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
  }

  &Copy {
    p {
      @include font-reg($fs: 1.4rem);
      line-height: 16px;
      color: $color-gray-3;
    }
  }

  &Policy {
    display: flex;
    gap: 48px;

    span {
      color: $color-gray-3;
      @include font-reg($fs: 1.4rem);
      line-height: 16px;
      cursor: pointer;
      position: relative;

      &::after {
        content: "";
        height: 100%;
        background-color: $color-green-5;
        position: absolute;
        width: 1px;
        right: -24px;
      }
    }

    span:hover {
      border-bottom: 1px solid $color-gray-3;
    }

    p {
      color: $color-gray-3;
      @include font-reg($fs: 1.4rem);
      line-height: 16px;
      cursor: pointer;
      position: relative;
    }

    p:hover {
      border-bottom: 1px solid $color-gray-3;
    }
  }

  @media (max-width: 992px) {
    padding: 48px 0 24px;

    &Logos {
      p {
        @include font-reg($fs: 1.4rem);
        line-height: 160%;
        max-width: 100%;
      }
    }

    &Container {
      flex-direction: column;
      padding-bottom: 24px;
    }

    &Strips {
      flex-direction: column;
      gap: 8px;
      padding-top: 24px;
    }

    &SocialLinks {
      padding-bottom: 32px;
    }

    &NavTab {
      ul {
        padding-bottom: 24px;
      }

      li {
        padding-bottom: 24px;
      }
    }

    &NavLinks {
      ul {
        padding: 24px 0 16px;
      }

      li {
        padding-bottom: 16px;
      }
    }
  }
}
