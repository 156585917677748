@import "/src/styles/common.scss";
@import "/src/styles/variables.scss";

.contactBanner {
  background: url(../../assets/images/contactUs/contactBanner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 65% 100%;
  height: 51vh;
  margin-top: 56px;

  &Container {
    height: 100%;
  }

  &Heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 751px;

    .mobileImg {
      display: none;
    }

    h1 {
      color: $color-white-0;
      @include font-bold($fs: 4.8rem);
      line-height: 140%;
      padding-bottom: 16px;
    }

    p {
      color: $color-white-0;
      @include font-reg($fs: 2rem);
      line-height: 32px;
    }
  }

  @media (max-width: 992px) {
    &Heading {
      h1 {
        @include font-bold($fs: 2.4rem);
        padding-bottom: 8px;
      }

      p {
        @include font-reg($fs: 1.6rem);
        line-height: 26px;
      }
    }
  }
}

@media (max-width: 600px) {
  .contactBanner {
    background-image: url(../../assets/images/contactUs/contactResponsive.png);
    background-repeat: no-repeat;
    background-position: 100%;
    height: 63vh;
    margin-top: 56px;

    &Heading {
      .mobileImg {
        display: block;
        background-position: 65% 100%;
      }
    }
  }
}

// Enquiry
.enquiry {
  padding: 40px 0;
  background: $color-white-0;

  &Cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid $color-green-4;
    background: $color-green-4;
    padding: 40px;
    max-width: 1085px;
    margin: 0 auto;

    .enquiryHeading {
      color: $color-green-1;
      @include font-bold($fs: 2.4rem);
      padding: 0 0 8px;
      display: inline-block;
    }

    p {
      color: $color-gray-3;
      @include font-reg($fs: 1.6rem);
      line-height: 26px;
      max-width: 424px;
    }
  }

  &Icon {
    display: flex;
    gap: 32px;
    justify-content: space-between;

    img {
      width: 64px;
      height: 64px;
    }
  }

  &Contact {
    display: flex;
    gap: 40px;
    padding-top: 8px;

    div {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      padding: 8px 0;

      img {
        display: block;
        width: 22px;
        height: 22px;
      }

      span {
        color: $color-blue-0;
        line-height: 26px;
        @include font-reg($fs: 1.6rem);
        cursor: pointer;
      }
    }
  }

  @media (max-width: 992px) {
    padding: 24px 0;

    &Cards {
      display: block;
      padding: 24px;
    }

    &Icon {
      display: block;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &Cards {
      h4 {
        font-size: 18px;
      }

      p {
        max-width: 100%;
        font-size: 14px;
        line-height: 160%;
        padding-bottom: 16px;
      }

      .enquiryHeading {
        @include font-bold($fs: 2rem);
        padding: 16px 0 8px;
      }
    }

    &Contact {
      flex-direction: column;
      gap: 16px;
      padding: 0;

      div {
        align-items: center;
        padding: 0;

        span {
          @include font-reg($fs: 1.4rem);
          line-height: 16px;
        }

        img {
          width: 16px;
        }
      }
    }
  }
}

// Conversation
.conversation {
  padding: 100px 0;

  &TeamHeading {
    display: flex;
    border-bottom: 1px solid $color-green-4;
    max-width: 450px;
    margin: 0 auto;
    cursor: pointer;

    .active {
      border-bottom: 2px solid var(--Primary, #009a4a);
      color: $color-green-1;
      padding-bottom: 6px;
    }

    li {
      color: $color-gray-3;
      @include font-semi-bold($fs: 2rem);
      display: flex;
      gap: 10px;
      //To do max-width: 225px;
      width: 100%;
      padding-bottom: 8px;
      justify-content: center;

      img {
        width: 32px;
      }
    }
  }

  &Tabs {
    display: flex;
    gap: 64px;

    &Maps {
      padding: 32px 0 24px;
    }

    &Address {
      p {
        @include font-reg($fs: 1.6rem);
        line-height: 26px;
        color: $color-gray-3;
        max-width: 568px;
      }
    }

    &Direction {
      a {
        color: $color-green-0;
        @include font-med($fs: 1.6rem);
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 16px;

        &:hover {
          color: $color-green-7;

          svg {
            transform: translate(4px, -4px);
            transition: all 0.2s ease;

            path {
              stroke-width: 1.5px;
            }
          }
        }
      }
    }

    &FormHeading {
      span {
        color: $color-blackCurrant-0;
        @include font-bold($fs: 4rem);
      }

      p {
        color: $color-blackCurrant-0;
        @include font-reg($fs: 1.6rem);
        line-height: 26px;
        padding: 8px 0 32px;
      }
    }
  }

  &ContactForm {
    width: 100%;

    &Input {
      display: flex;
      justify-content: space-between;
      margin: 0 0 12px;

      div {
        width: calc(50% - 12px);
      }

      label {
        color: $color-green-1;
        @include font-reg($fs: 1.6rem);
        line-height: 26px;
        padding-bottom: 8px;
        display: inline-block;

        span {
          color: $color-red-0;
          @include font-reg($fs: 1.6rem);
          line-height: 26px;
        }
      }

      input {
        @include font-reg($fs: 1.4rem);
        line-height: 16px;
        padding: 14px 15px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid $color-OysterBay-0;
        outline: 0;
        background: $color-white-0;

        &::placeholder {
          @include font-reg($fs: 1.4rem);
          color: $color-gray-5;
        }

        &:focus {
          border: 1px solid $color-gray-3;
        }
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
      .phoneInput {
        width: 100%;
        position: relative;

        input {
          width: 100%;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 14.5px;
          left: 14px;
          color: #000;
          font-size: 14px;
        }

        &:focus-within {
          input {
            padding-left: 40px;
          }

          &::before {
            content: "+91";
          }
        }
      }
    }

    &TextArea {
      .form-control {
        width: 100%;
        border-radius: 4px;
        border: 1px solid $color-green-4;
        background: #fff;
        padding: 14px 15px;
        outline: 0;
        resize: none;

        &:focus {
          border: 1px solid $color-gray-3;
        }

        &::placeholder {
          @include font-reg($fs: 1.4rem);
          color: $color-gray-5;
        }
      }

      p {
        @include font-reg($fs: 1.2rem);
        color: $color-green-1;
        line-height: 14px;
        padding-top: 12px;

        span {
          color: $color-red-0;
          @include font-reg($fs: 1.2rem);
          line-height: 14px;
        }
      }

      label {
        color: $color-green-1;
        @include font-reg($fs: 1.6rem);
        line-height: 26px;
        padding-bottom: 8px;
        display: inline-block;
      }

      input {
        @include font-reg($fs: 1.4rem);
        line-height: 16px;
        padding: 14px 15px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid $color-OysterBay-0;
        background: $color-white-0;
        padding-bottom: 12px;

        &::placeholder {
          @include font-reg($fs: 1.4rem);
          color: $color-gray-5;
        }
      }

      input[type="text"]:focus {
        outline: 1px solid $color-gray-3;
      }
    }

    &Buttons {
      border-radius: 4px;
      // opacity: 0.3;
      background: $color-green-0;
      max-width: 272px;
      text-align: center;
      margin-top: 32px;

      button {
        width: 100%;
        color: $color-white-0;
        @include font-med($fs: 1.6rem);
        border-radius: 4px;
        background: $color-green-0;
        padding: 10px 24px;
        border: 0;
        outline: 0;
        text-align: center;
        cursor: pointer;
      }

      button:hover {
        background-color: $color-green-7;
      }
    }
  }

  @media (max-width: 992px) {
    padding: 48px 0;

    &TeamHeading {
      max-width: 100%;

      li {
        @include font-semi-bold($fs: 1.8rem);
        align-items: center;

        img {
          width: 32px;
        }
      }
    }

    &Tabs {
      flex-direction: column;
      gap: 40px;

      &Maps {
        padding: 24px 0;

        img {
          width: 100%;
        }
      }

      &Address {
        p {
          max-width: 100%;
          @include font-reg($fs: 1.4rem);
          line-height: 160%;
        }
      }

      &Direction {
        a {
          @include font-med($fs: 1.4rem);
          gap: 8px;
        }
      }

      &FormHeading {
        span {
          @include font-bold($fs: 2.4rem);
        }

        p {
          padding: 8px 0 24px;
          @include font-reg($fs: 1.4rem);
          line-height: 160%;
        }
      }

      div {
        width: 100%;
      }
    }

    &ContactForm {
      &Input {
        flex-direction: column;
        margin: 0;

        label {
          @include font-reg($fs: 1.4rem);
        }

        input {
          margin-bottom: 16px;
        }

        input :nth-child(3) {
          margin-bottom: 0;
        }
      }

      &TextArea {
        p {
          padding-top: 16px;
        }
      }

      &Buttons {
        max-width: 100%;
      }
    }
  }
}
